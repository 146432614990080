<template>
  <template v-if="stream.isStream">
    <div
      class="streams__stream stream"
      :class="{ stream_fullscreen: stream.isFullscreen }"
      :data-insertmedia="stream.isActive && JSON.stringify(stream.options)"
    >
      <div class="stream__content">
        Your stream can be here<br />
        <router-link
          :to="{ name: 'Media' }"
          data-inner-select="media"
          class="stream__link"
          >ADD</router-link
        >
      </div>
    </div>
  </template>
  <template v-else>
    <div class="streams__stream stream">
      <router-link :to="{ name: 'Media' }">
        <div class="stream__add" data-inner-select="media"></div>
      </router-link>
    </div>
  </template>
</template>

<script>
export default {
  name: "StreamItem",
  data() {
    return {
      opts: true,
    };
  },
  props: {
    stream: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
  display: flex;
  opacity: 1;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  display: none;
}
</style>
