<template>
  <div class="gw-burger" @click="clickMenuSwitcher" data-menu-switcher>
    <div class="gw-burger__open"></div>
    <div class="gw-burger__close"></div>
  </div>
</template>

<script>
export default {
  name: "BurgerMenu",
  mounted() {
    let resizeTimeoutNav;
    window.addEventListener(
      "resize",
      function () {
        clearTimeout(resizeTimeoutNav);
        resizeTimeoutNav = setTimeout(function () {
          if (document.body.clientWidth >= 1350) {
            document.body.classList.remove("mob-nav-active");
          }
        }, 100);
      },
      true
    );
  },
  methods: {
    scroll_go(target, behavior, offset) {
      let el = document.querySelector(target ?? "body");
      if (!el) return;
      let coords = el.getBoundingClientRect();
      window.scrollTo({
        top: coords.top + window.scrollY + parseInt(offset ?? "0"),
        behavior: behavior ?? "smooth",
      });
    },
    clickMenuSwitcher() {
      if (document.body.clientWidth >= 650) {
        this.scroll_go("body", "instant");
      }
      document.body.classList.toggle("mob-nav-active");
    },
  },
};
</script>

<style scoped></style>
