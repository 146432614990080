<template>
  <div class="field field_grp field_mb fit-cont">
    <div class="field__desc">
      <div class="field__ico">
        <img src="@/assets/images/icons/ico__page.png" alt="" />
      </div>
      <div class="field__title">Page</div>
    </div>
    <select
      name="page"
      class="tt-uppercase"
      @change="selector_change"
      v-model="modal_target"
      data-track-stab
      data-services-list
    >
      <template v-if="modalSelectorOptions">
        <option
          v-for="(option, index) in modalSelectorOptions"
          :key="index"
          :value="option.id"
        >
          {{ option.name }}
        </option>
      </template>
    </select>
  </div>
</template>

<script>
export default {
  name: "ModalSelectorProps",
  props: {
    modalSelectorOptions: Object,
  },
  computed: {
    modal_target: {
      get() {
        return this.$store.getters.get_modal_target;
      },
      set(target) {
        this.$store.commit("UPDATE_MODAL_TARGET", target);
      },
    },
    modal_selector_options: {
      get() {
        return this.$store.getters.get_modal_selector_options;
      },
      set(target) {
        this.$store.commit("UPDATE_MODAL_SELECTOR_OPTIONS", target);
      },
    },
  },
  methods: {
    selector_change(e) {
      this.modal_target = e.target.value;
      this.$router.push("/" + e.target.value);
    },
  },
};
</script>

<style scoped></style>
