import signupConstants from "@/assets/js/signup.constants";

export default {
  /**
   * ACTIONS
   */
  actions: {
    init_signup({ commit, state }) {
      if (state.prefixList.length < signupConstants.PREFIX_MAX_LENGTH) {
        commit("INIT_PREFIX_LIST");
      }
    },
  },
  /**
   * MUTATIONS
   */
  mutations: {
    async INIT_PREFIX_LIST(state) {
      if (state.prefixList.length === state.prefixListCount) {
        state.prefixList = [];
      }
      let i = 0;
      let min = Math.ceil(signupConstants.PREFIX_MIN);
      let max = Math.floor(signupConstants.PREFIX_MAX);
      for (i; i < state.prefixListCount; i++) {
        state.prefixList.push(Math.floor(Math.random() * (max - min) + min));
      }
    },
  },
  /**
   * STATE
   */
  state: {
    prefixListCount: 4,
    prefixList: [],
  },
  /**
   * GETTERS
   */
  getters: {
    getPrefixList(state) {
      return state.prefixList;
    },
  },
};
