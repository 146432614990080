<template>
  <div class="section" data-section="scl">
    <div class="container">
      <div class="scl">
        <a
          v-for="(soc, index) in social_icons"
          :key="index"
          :href="soc.href"
          target="_blank"
          class="scl__link"
        >
          <img :src="soc.imgSrc" :alt="soc.alt" class="scl__img" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SocialIcons",
  data() {
    return {
      social_icons: [
        {
          href: "https://www.youtube.com/channel/UC9qYBgfV4IX8Ycs9Su5KO3A",
          imgSrc: require("@/assets/images/scl/youtube.webp"),
          alt: "youtube",
        },
        {
          href: "https://www.facebook.com/La2DreamSociety",
          imgSrc: require("@/assets/images/scl/facebook.webp"),
          alt: "facebook",
        },
        {
          href: "https://twitter.com/La2Dream_com",
          imgSrc: require("@/assets/images/scl/twitter.webp"),
          alt: "twitter",
        },
        {
          href: "https://vk.com/mmorpg_society",
          imgSrc: require("@/assets/images/scl/vkontakte.webp"),
          alt: "vkontakte",
        },
        {
          href: "#soon",
          imgSrc: require("@/assets/images/scl/instagram.webp"),
          alt: "instagram",
        },
        {
          href: "https://discord.gg/dURKWPpky8",
          imgSrc: require("@/assets/images/scl/discord.webp"),
          alt: "discord",
        },
        {
          href: "skype:la2dream.com?add",
          imgSrc: require("@/assets/images/scl/skype.webp"),
          alt: "skype",
        },
        {
          href: "mailto:support@la2dream.com",
          imgSrc: require("@/assets/images/scl/mail.webp"),
          alt: "mail",
        },
        {
          href: "https://t.me/la2dream_com",
          imgSrc: require("@/assets/images/scl/telegram.webp"),
          alt: "telegram",
        },
      ],
    };
  },
};
</script>

<style scoped></style>
