<template>
  <div class="bar">
    <template v-if="this.enable_l2oops">
      <a href="https://l2oops.com" style="z-index: 99999" target="_blank"
        ><img
          src="@/assets/images/banner/black_l2oops_mini.png"
          width="122"
          height="31"
          alt="New servers Lineage 2"
          title="New servers Lineage 2"
      /></a>
    </template>
    <FunctionBtn
      v-for="(btn, index) in function_buttons"
      :key="index"
      :btn="btn"
    ></FunctionBtn>
    <LanguageSwitcher></LanguageSwitcher>
    <BurgerMenu></BurgerMenu>
  </div>
</template>

<script>
import FunctionBtn from "@/components/Header/FunctionBtn";
import LanguageSwitcher from "@/components/Header/LanguageSwitcher";
import BurgerMenu from "@/components/Header/BurgerMenu";

export default {
  name: "RightBar",
  components: { BurgerMenu, LanguageSwitcher, FunctionBtn },
  data() {
    return {
      enable_l2oops: false,
    };
  },
  async mounted() {
    /*let enable_l2oops_str = await this.$store.dispatch(
      "find_config_item",
      "enable_l2oops"
    );*/
    let enable_l2oops_str = null;
    this.enable_l2oops =
      enable_l2oops_str === "1" || enable_l2oops_str === "true";
  },
  computed: {
    function_buttons() {
      if (this.$store.getters.get_logged_in) {
        return [
          {
            imgSrc: require("@/assets/images/btns/ico-acc.png"),
            alt: "account",
            route: "/account",
            routeName: "account",
          },
        ];
      } else {
        return [
          {
            imgSrc: require("@/assets/images/btns/ico-acc.png"),
            alt: "login",
            route: "/login",
            routeName: "login",
          },
        ];
      }
    },
  },
};
</script>

<style scoped></style>
