import axios from "axios";
import cfg_axios from "@/config/cfg_axios";
import { __config } from "@/assets/js/config";

export default {
  /**
   * ACTIONS
   */
  actions: {
    async set_default_configs({ dispatch }) {
      try {
        const server_id = await dispatch(
          "find_config_item",
          "default_server_id"
        );
        await dispatch("update_default_server", server_id);
      } catch (e) {
        console.log(e);
      }
    },
    find_config_item({ getters }, value) {
      try {
        const index = getters.get_config.public
          .map((f) => f.key)
          .indexOf(value);

        if (index < 0) {
          return null;
        }

        return getters.get_config.public[index].value;
      } catch (e) {
        console.error(`could not find config item '${value}'`, e);
      }
    },
    init_config({ commit, dispatch }) {
      try {
        if (localStorage.config && localStorage.config_expired) {
          const config = JSON.parse(localStorage.config);
          commit("UPDATE_CONFIG", config);
          commit("UPDATE_SERVERS", config.servers);
          commit("UPDATE_CONFIG_EXPIRED", localStorage.config_expired);
          dispatch("set_default_configs", config.public);
        }
      } catch (e) {
        // console.log(e.message);
      }
    },
    init_forum({ dispatch, commit }) {
      try {
        if (
          localStorage.news &&
          localStorage.events &&
          localStorage.topics &&
          localStorage.forum_expired
        ) {
          dispatch("update_media_news", JSON.parse(localStorage.news));
          dispatch("update_media_events", JSON.parse(localStorage.events));
          dispatch("update_media_topics", JSON.parse(localStorage.topics));
          commit("UPDATE_FORUM_EXPIRED", localStorage.forum_expired);
        }
      } catch (e) {
        // console.log(e.message);
      }
    },
    async fetch_config({ commit, dispatch }) {
      await axios
        .get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/config`, {
          headers: cfg_axios.headers,
        })
        .then((res) => {
          commit("UPDATE_CONFIG", res.data);
          commit("UPDATE_SERVERS", res.data.servers);
          commit(
            "UPDATE_CONFIG_EXPIRED",
            new Date(new Date().getTime() + 5 * 60 * 1000).getTime()
          );
          dispatch("set_default_configs", res.data);
        })
        .catch((e) => {
          console.error("fetch_config [ERROR]:", e.message);
        });
    },
    async fetch_forum({ commit, dispatch }) {
      await axios
        .get(`${process.env.VUE_APP_API_BASE_URL}/api/v3/forum`, {
          headers: cfg_axios.headers,
        })
        .then((res) => {
          dispatch("update_media_news", res.data.news);
          dispatch("update_media_events", res.data.events);
          dispatch("update_media_topics", res.data.topics);
          commit(
            "UPDATE_FORUM_EXPIRED",
            new Date(new Date().getTime() + 5 * 60 * 1000).getTime()
          );
        })
        .catch((e) => {
          console.error("fetch_forum [ERROR]:", e.message);
        });
    },
  },
  /**
   * MUTATIONS
   */
  mutations: {
    UPDATE_CONFIG(state, config) {
      try {
        localStorage.config = JSON.stringify(config);
        state.config = config;
      } catch (e) {
        state.config = config;
      }
    },
    UPDATE_CONFIG_EXPIRED(state, expired) {
      try {
        localStorage.config_expired = expired;
        state.config_expired = expired;
      } catch (e) {
        state.config_expired = expired;
      }
    },
    UPDATE_FORUM_EXPIRED(state, expired) {
      try {
        localStorage.forum_expired = expired;
        state.forum_expired = expired;
      } catch (e) {
        state.forum_expired = expired;
      }
    },
  },
  /**
   * STATE
   */
  state: {
    config: null,
    config_expired: null,
    forum_expired: null,
    server_description: __config.server.server_desc_by_id[2],
  },
  /**
   * GETTERS
   */
  getters: {
    get_config(state) {
      return state.config;
    },
    get_config_expired(state) {
      return state.config_expired;
    },
    get_forum_expired(state) {
      return state.forum_expired;
    },
    get_server_description(state) {
      return state.server_description;
    },
  },
};
