<template>
  <div class="section" data-section="navigation">
    <div class="box">
      <Logo></Logo>
      <Navigation :menuItems="menuItems"></Navigation>
      <RightBar></RightBar>
    </div>
  </div>
</template>

<script>
import RightBar from "@/components/Header/RightBar";
import Navigation from "@/components/Header/Navigation";
import Logo from "@/components/Header/Logo";

export default {
  name: "NavigationSection",
  components: { Logo, Navigation, RightBar },
  data() {
    return {
      menuItems: [
        {
          id: "signup",
          name: "Registration",
          description: "Create account",
          router: {
            name: "Signup",
          },
          imgSrc: require("@/assets/images/navigation/ico-1.webp"),
          alt: "Create account",
          isModal: true,
        },
        {
          id: "files",
          name: "Download",
          description: "Game files",
          router: {
            name: "Downloads",
          },
          imgSrc: require("@/assets/images/navigation/ico-2.webp"),
          alt: "Game files",
          isModal: true,
        },
        // {
        //   id: "support",
        //   name: "Поддержка",
        //   description: "Сервисы",
        //   router: {
        //     name: "Support",
        //   },
        //   imgSrc: require("@/assets/images/navigation/ico-3.webp"),
        //   alt: "База знаний",
        //   isModal: true,
        // },
        {
          id: "forum",
          name: "Forum",
          description: "Community",
          router: {},
          link: "https://forum.la2dream.com/",
          imgSrc: require("@/assets/images/navigation/ico-4.webp"),
          alt: "Community",
          isModal: false,
        },
        {
          id: "server",
          name: "Wiki",
          description: "Knowledge base",
          router: {
            name: "Server",
            params: {
              id: this.$store.state.default_server,
            },
          },
          imgSrc: require("@/assets/images/navigation/ico-6.png"),
          alt: "Knowledge base",
          isModal: true,
        },
        {
          id: "shop",
          name: "Shop",
          description: "Payment",
          router: {
            name: "Shop",
          },
          imgSrc: require("@/assets/images/navigation/ico-5.webp"),
          alt: "Payment",
          isModal: true,
        },
      ],
    };
  },
};
</script>

<style scoped></style>
