<template>
  <a :href="item.href" class="item">
    <div class="item__date">{{ item.date }}</div>
    <div class="item__content">
      {{ item.title }}
    </div>
    <div class="item__btns">
      <a :href="item.url" target="_blank">
        <div class="btn btn_size_small">
          <div class="btn__content">More</div>
        </div>
      </a>
    </div>
  </a>
</template>

<script>
export default {
  name: "TabItem",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>
