<template>
  <div class="page">
    <Preload @preloadFinished="preloadFinish"></Preload>
    <NavigationSection></NavigationSection>
    <Servers></Servers>
    <Media></Media>
    <SocialIcons></SocialIcons>
    <transition>
      <Streams></Streams>
    </transition>
    <Footer></Footer>
    <FooterPayments></FooterPayments>
  </div>
  <Modals></Modals>
</template>

<script>
import Preload from "@/components/Body/Preload";
import Media from "@/components/Body/Media/Media";
import NavigationSection from "@/components/Header/NavigationSection";
import Servers from "@/components/Body/Servers/Servers";
import SocialIcons from "@/components/Body/Social/SocialIcons";
import Streams from "@/components/Body/Stream/Streams";
import Footer from "@/components/Footer/Footer";
import FooterPayments from "@/components/Footer/FooterPayments";
import Modals from "@/components/Modal/Modals";

import "@/assets/libs/jquery.cookie/jquery.cookie.min";
import "@/assets/libs/circle-progress/js/circle-progress.min";
import "@/assets/libs/select2/js/select2.full.min";

export default {
  name: "App",
  components: {
    Preload,
    Media,
    Servers,
    NavigationSection,
    Modals,
    FooterPayments,
    Footer,
    Streams,
    SocialIcons,
  },
  beforeMount() {
    document.addEventListener("DOMContentLoaded", function () {
      let script = document.createElement("script");
      script.src = `https://translate.yandex.net/website-widget/v1/widget.js?widgetId=ytWidget&pageLang=en&widgetTheme=light&autoMode=false`;
      document.getElementsByTagName("head")[0].appendChild(script);
    });
  },
  created() {
    this.$store.commit("INIT_LANG");
    this.$store.dispatch("init_account");
    this.$store.dispatch("check_session");
    this.$store.dispatch("init_streams");
  },
  data() {
    return {
      current_route: window.location.pathname,
      hide_modal_routes: ["/", "/logout"],
      toast_opts: {
        position: "top-right",
      },
    };
  },
  computed: {
    findRoute() {
      return this.$router.options.routes.filter((route) => {
        return route.path === this.$route.path;
      });
    },
    preload_ended: {
      get() {
        return this.$store.state.is_preload_ended;
      },
    },
  },
  methods: {
    preloadFinish() {
      if (this.hide_modal_routes.indexOf(this.$route.path) === -1) {
        if (this.$store.getters.is_preload_ended) {
          this.$store.dispatch("enable_modal");
          this.$store.dispatch("update_show_modal", true);
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch(
      "check_modal_dropdown",
      this.$route.path.split("/")[1]
    );

    if (this.$route.path !== "/") {
      if (this.findRoute.length > 0) {
        this.$router.push({ path: this.$route.path });
      } /* else {
        this.$store.dispatch("update_show_modal", false);
      }*/

      this.$store.dispatch(
        "update_modal_target",
        this.current_route.split("/")[1]
      );
      this.$store.dispatch("update_body");
    }
  },
  watch: {
    async $route(to, from) {
      if (from.path === "/logout" && to.path === "/") {
        this.$store.dispatch("close_modal");
      }

      await this.$store.dispatch("update_current_route", to.path);
      await this.$store.dispatch("update_modal_target", to.path.split("/")[1]);
      await this.$store.dispatch("update_is_modal_wide", to.path.split("/")[1]);
      await this.$store.dispatch(
        "update_current_server",
        to.path.split("/")[2]
      );
    },
  },
};
</script>

<style>
@import "assets/fonts/AlbertusExtraBold/stylesheet.css";
@import "assets/fonts/AlegreyaSansSC/stylesheet.css";
@import "assets/libs/select2/css/select2.min.css";
@import "assets/css/main.css";
</style>
