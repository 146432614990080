<template>
  <div class="mid__content boxshadow">
    <div class="tabs">
      <div class="tabs__btns">
        <div class="tabs__btn">News</div>
        <div class="tabs__btn">Events</div>
        <div class="tabs__btn">Forum</div>
        <router-link
          :to="{
            name: 'Statistics',
            params: {
              id: this.$store.getters.get_current_server,
            },
          }"
          class="tabs__btn tabs__btn_link"
          data-inner-select="stats"
          >Statistics</router-link
        >
      </div>
      <div class="tabs__content">
        <div class="tabs__tab">
          <div class="items">
            <div class="items__list items__list_short">
              <TabItem
                v-for="(article, index) in news"
                :key="index"
                :item="article"
              ></TabItem>
            </div>
            <div class="tabs__more">
              <a
                href="https://forum.la2dream.com/forum/4-news-announcements/"
                target="_blank"
                class="btn btn_size_large"
              >
                <div class="btn__content">More news</div>
              </a>
            </div>
          </div>
        </div>
        <!-- END tabs__tab -->
        <div class="tabs__tab">
          <div class="items">
            <div class="items__list items__list_short">
              <TabItem
                v-for="(event, index) in events"
                :key="index"
                :item="event"
              ></TabItem>
            </div>
            <div class="tabs__more">
              <a
                href="https://forum.la2dream.com/forum/27-bonuses/"
                target="_blank"
                class="btn btn_size_large tabs__more"
              >
                <div class="btn__content">More events</div>
              </a>
            </div>
          </div>
        </div>
        <!-- END tabs__tab -->
        <div class="tabs__tab">
          <div class="items">
            <div class="items__list items__list_short">
              <TabItem
                v-for="(topic, index) in topics"
                :key="index"
                :item="topic"
              ></TabItem>
            </div>
            <div class="tabs__more">
              <a
                href="https://forum.la2dream.com/"
                target="_blank"
                class="btn btn_size_large tabs__more"
              >
                <div class="btn__content">Visit forum</div>
              </a>
            </div>
          </div>
        </div>
        <!-- END tabs__tab -->
      </div>
      <!-- END tabs__content -->
    </div>
    <!-- END tabs -->
  </div>
</template>

<script>
import TabItem from "@/components/Body/Media/Tabs/TabItem";
import { initTabs } from "@/assets/js/tabs";

export default {
  name: "Tabs",
  components: { TabItem },
  data() {
    return {};
  },
  computed: {
    news: {
      get() {
        return this.$store.getters.get_media_news;
      },
      set(value) {
        this.$store.dispatch("update_media_news", value);
      },
    },
    events: {
      get() {
        return this.$store.getters.get_media_events;
      },
      set(value) {
        this.$store.dispatch("update_media_events", value);
      },
    },
    topics: {
      get() {
        return this.$store.getters.get_media_topics;
      },
      set(value) {
        this.$store.dispatch("update_media_topics", value);
      },
    },
  },
  mounted() {
    initTabs();
  },
};
</script>

<style scoped></style>
