<template>
  <div id="ytWidget" style="display: none"></div>
  <div class="lang">
    <div
      class="lang__link"
      data-inner-select="languages"
      @click.prevent="$router.push({ name: 'Languages' })"
    >
      <img
        :src="this.generateLanguageIcon"
        data-lang-active
        :alt="this.get_lang"
      />
    </div>
    <div class="lang__hint hint notranslate">
      <div class="hint__list hint__list_decor lang__list">
        <template v-for="(lang, index) in languages" :key="index">
          <a
            href="#"
            :data-ya-lang="lang.dataLang"
            class="hint__item lang__item"
            :class="{
              hint__item_rr: lang.dataLang === 'en',
              hint__item_rl: lang.dataLang === 'no',
            }"
            :title="lang.name"
            @click="langClickHandler($event)"
            translate="no"
          >
            <img
              :src="generateImgSrc(lang.imgSuffix)"
              :alt="lang.dataLang"
              class="lang__img"
            />
            <div class="hint__content">{{ lang.name }}</div>
          </a>
        </template>
      </div>
    </div>
    <!-- END hint -->
  </div>
</template>

<script>
export default {
  name: "LanguageSwitcher",
  methods: {
    generateImgSrc(lang = "en") {
      return require(`@/assets/images/lang/lg__${lang}.png`);
    },
    langClickHandler(e) {
      let lang =
        e.target.getAttribute("data-ya-lang") ||
        e.target.parentNode.getAttribute("data-ya-lang");

      let title =
        e.target.getAttribute("title") ||
        e.target.parentNode.getAttribute("title");

      let flag = this.languages.filter((lang) => lang.name === title)[0]
        .imgSuffix;

      this.$store.dispatch("update_lang", lang);
      this.$store.dispatch("update_flag", flag);
      window.location.reload();
      return false;
    },
  },
  computed: {
    get_lang: {
      get() {
        try {
          return JSON.parse(localStorage.getItem("yt-widget")).lang;
        } catch (e) {
          return this.$store.getters.get_lang;
        }
      },
    },
    get_flag: {
      get() {
        try {
          return localStorage.flag;
        } catch (e) {
          return this.$store.getters.get_flag;
        }
      },
    },
    generateLanguageIcon() {
      return require(`@/assets/images/lang/lg__${this.get_flag}.png`);
    },
    languages: {
      get() {
        const langs = this.$store.getters.get_languages;
        return langs.reverse();
      },
    },
  },
};
</script>

<style scoped></style>
