<template>
  <router-link
    :to="{ path: btn.route }"
    :data-inner-select="btn.routeName"
    class="fbtn"
  >
    <div class="fbtn__pic">
      <img :src="btn.imgSrc" :alt="btn.alt" class="fbtn__img" />
    </div>
  </router-link>
</template>

<script>
export default {
  name: "FunctionBtn",
  props: {
    btn: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped></style>
