import { createToaster } from "@meforma/vue-toaster";
import router from "@/router";
import axios from "axios";
import cfg_axios from "@/config/cfg_axios";

const $router = router;
const $toaster = createToaster({
  /* options */
});

export default {
  /**
   * ACTIONS
   */
  actions: {
    auth_user({ commit }, response) {
      if (response.message.toString() === "success") {
        commit("SET_AUTH_TOKEN", response.auth_token);
        commit("SET_USERNAME", response.username);
        commit("SET_CHARACTERS", response.characters);
        commit("SET_ISSUED_AT", new Date(response.issued_at * 1000));
        commit("SET_EXPIRED_AT", new Date(response.expired_at * 1000));
        commit("SET_LOGGED_IN", true);

        $toaster.success("Account logged in", {
          position: "top-right",
        });
        setTimeout($toaster.clear, 3500);
        $router.push({ name: "Account" });
      }
    },
    logout_user({ commit, state }) {
      if (state.logged_in) {
        const form_data = new FormData();
        form_data.append("auth_token", state.auth_token);
        axios
          .post(cfg_axios.base_url + "/auth/logout", form_data, {
            headers: cfg_axios.headers,
          })
          .then((response) => {
            if (response.data) {
              if (response.data.message) {
                console.log("response [auth/logout]", response.data);
              }
            }
            $toaster.success("Account logged out", {
              position: "top-right",
            });
          })
          .catch((error) => {
            if (error.response.data) {
              console.log("error [auth/logout]", error.response.data);
            }
          });
      }

      commit("UNSET_AUTH_TOKEN");
      commit("UNSET_USERNAME");
      commit("UNSET_CHARACTERS");
      commit("UNSET_ISSUED_AT");
      commit("UNSET_EXPIRED_AT");
      commit("UNSET_LOGGED_IN");
    },
    init_account({ commit }) {
      try {
        if (
          localStorage.auth_token &&
          localStorage.username &&
          localStorage.characters &&
          localStorage.logged_in &&
          localStorage.issued_at &&
          localStorage.expired_at
        ) {
          commit("SET_AUTH_TOKEN", localStorage.auth_token);
          commit("SET_USERNAME", localStorage.username);
          commit("SET_CHARACTERS", JSON.parse(localStorage.characters));
          commit("SET_ISSUED_AT", localStorage.issued_at);
          commit("SET_EXPIRED_AT", localStorage.expired_at);
          commit("SET_LOGGED_IN", localStorage.logged_in);
        }
      } catch (e) {
        // console.log(e.message);
      }
    },
    check_session({ state, dispatch, commit }) {
      if (!state.auth_token) return;
      const form_data = new FormData();
      form_data.append("auth_token", state.auth_token);
      form_data.append("username", state.username);
      axios
        .post(cfg_axios.base_url + "/auth/session_check", form_data, {
          headers: cfg_axios.headers,
        })
        .then((response) => {
          if (response.data.message === "success") {
            // success
            if (response.data.new_data === true) {
              commit("SET_CHARACTERS", response.data.characters);
            }
          } else {
            if (response.data.messages) {
              console.error(
                "check session error",
                response.data.messages.error
              );
            }
          }
        })
        .catch((error) => {
          dispatch("logout_user");
          $toaster.error(error.response.data.messages.error, {
            position: "top-right",
          });
          setTimeout($toaster.clear, 3500);
          commit("SET_AUTH_EXPIRED_FLAG", true);
          router.push("/");
        });
    },
  },
  /**
   * MUTATIONS
   */
  mutations: {
    SET_AUTH_EXPIRED_FLAG(state, value) {
      try {
        localStorage.auth_expired_flag = value;
        state.auth_expired_flag = value;
      } catch (e) {
        state.auth_expired_flag = value;
      }
    },
    SET_CHARACTERS(state, characters) {
      try {
        localStorage.characters = JSON.stringify(characters);
        state.characters = characters;
      } catch (e) {
        state.characters = characters;
      }
    },
    SET_USERNAME(state, username) {
      try {
        localStorage.username = username;
        state.username = username;
      } catch (e) {
        state.username = username;
      }
    },
    SET_AUTH_TOKEN(state, token) {
      try {
        localStorage.auth_token = token;
        state.auth_token = token;
      } catch (e) {
        state.auth_token = token;
      }
    },
    SET_LOGGED_IN(state, value) {
      try {
        localStorage.logged_in = value;
        state.logged_in = value;
      } catch (e) {
        state.logged_in = value;
      }
    },
    SET_ISSUED_AT(state, value) {
      try {
        localStorage.issued_at = value;
        state.issued_at = value;
      } catch (e) {
        state.issued_at = value;
      }
    },
    SET_EXPIRED_AT(state, value) {
      try {
        localStorage.expired_at = value;
        state.expired_at = value;
      } catch (e) {
        state.expired_at = value;
      }
    },
    UNSET_CHARACTERS(state) {
      try {
        localStorage.removeItem("characters");
        state.characters = [];
      } catch (e) {
        state.characters = [];
      }
    },
    UNSET_USERNAME(state) {
      try {
        localStorage.removeItem("username");
        state.username = null;
      } catch (e) {
        state.username = null;
      }
    },
    UNSET_AUTH_TOKEN(state) {
      try {
        localStorage.removeItem("auth_token");
        state.auth_token = null;
      } catch (e) {
        state.auth_token = null;
      }
    },
    UNSET_LOGGED_IN(state) {
      try {
        localStorage.removeItem("logged_in");
        state.logged_in = false;
      } catch (e) {
        state.logged_in = false;
      }
    },
    UNSET_ISSUED_AT(state) {
      try {
        localStorage.removeItem("issued_at");
        state.issued_at = null;
      } catch (e) {
        state.issued_at = null;
      }
    },
    UNSET_EXPIRED_AT(state) {
      try {
        localStorage.removeItem("expired_at");
        state.expired_at = null;
      } catch (e) {
        state.expired_at = null;
      }
    },
  },
  /**
   * STATE
   */
  state: {
    auth_expired_flag: null,
    auth_token: null,
    characters: [],
    username: null,
    logged_in: false,
    issued_at: null,
    expired_at: null,
  },
  /**
   * GETTERS
   */
  getters: {
    get_auth_token(state) {
      return state.auth_token;
    },
    get_auth_expired_flag(state) {
      return state.auth_expired_flag;
    },
    get_characters(state) {
      return state.characters;
    },
    get_username(state) {
      return state.username;
    },
    get_logged_in(state) {
      return state.logged_in;
    },
    get_issued_at(state) {
      return state.issued_at;
    },
    get_expired_at(state) {
      return state.expired_at;
    },
  },
};
