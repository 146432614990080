export default {
  /**
   * ACTIONS
   */
  actions: {
    update_body({ commit }, body) {
      commit("UPDATE_BODY", body || document.getElementsByTagName("body")[0]);
    },
  },
  /**
   * MUTATIONS
   */
  mutations: {
    UPDATE_BODY(state, body) {
      state.body = body;
    },
  },
  /**
   * STATE
   */
  state: {
    body: null,
  },
  /**
   * GETTERS
   */
  getters: {
    get_body(state) {
      return state.body;
    },
  },
};
