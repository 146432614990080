<template>
  <div class="logotype">
    <a href="/" class="logo" title="area-game">
      <img src="@/assets/images/logo/logo.webp" alt="area-game" />
    </a>
  </div>
</template>

<script>
export default {
  name: "Logo",
};
</script>

<style scoped></style>
