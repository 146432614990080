<template v-show="!fetching_streams">
  <div class="section" data-section="streams">
    <div class="container">
      <div class="streams">
        <StreamItem
          v-for="(stream, index) in streams"
          :key="index"
          :stream="stream"
        ></StreamItem>
      </div>
    </div>
  </div>
</template>

<script>
import StreamItem from "@/components/Body/Stream/StreamItem";
import { insertMedia } from "@/assets/js/insertMedia";

export default {
  name: "Streams",
  components: { StreamItem },
  methods: {
    handleStreamsResize() {
      const wiw = window.innerWidth;
      const wih = window.innerHeight;

      if (wiw > 1000 && wih > 900) {
        clearTimeout(this.resizeTimeout);
        this.resizeTimeout = setTimeout(() => {
          const el_height = document.querySelector(
            "[data-section=streams]"
          ).offsetHeight;

          let stream_blocks = document.querySelectorAll(".stream_fullscreen");

          if (el_height > 256) {
            stream_blocks.forEach((stream) => {
              stream.classList.add("stream_fullscreen_visible");
            });
          } else {
            stream_blocks.forEach((stream) => {
              stream.classList.remove("stream_fullscreen_visible");
            });
          }
        }, 500);
      }
    },
  },
  computed: {
    fetching_streams() {
      return this.$store.getters.get_media_fetching_streams;
    },
    streams() {
      return this.$store.getters.get_media_streams;
    },
  },
  async mounted() {
    // Add event listener for the "resize" event on the element
    window.addEventListener("resize", this.handleStreamsResize);

    // Call the function initially to get the current element height
    this.handleStreamsResize();

    insertMedia({
      delay: 0,
      immediately: true,
      attr: "data-insertmedia",
    });

    this.$store.dispatch("update_body");
    this.$store.dispatch("init_modal_env");
  },
  beforeUnmount() {
    // Remove event listener when the component is unmounted
    window.removeEventListener("resize", this.handleStreamsResize);
  },
};
</script>

<style scoped></style>
